import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const RECENT_VIEWED_RECORDS = "RECENT_VIEWED_RECORDS";
const getStoredRecords = () => {
  let item = [];
  try {
    // Get from local storage by key
    item = window.localStorage.getItem(RECENT_VIEWED_RECORDS);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : [];
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return item;
  }
};

const storeRecord = (record) => {
  try {
    if (record) {
      let records = getStoredRecords();

      const recordExist = records.some(
        (rec) =>
          rec["title"] === record.title &&
          rec["originalId"] === record.originalId &&
          rec["documentType"] === record.documentType
      );

      if (!recordExist) {
        records.push(record);
      }

      if (records.length > 3) {
        records.shift();
      }

      window.localStorage.setItem(
        RECENT_VIEWED_RECORDS,
        JSON.stringify(records)
      );
    }
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};

export const performGetSingleTopicOrTrend = createAsyncThunk(
  "/report",
  async (params, { rejectWithValue, getState }) => {
    try {
      const request = {
        limit: 1,
        skip: 0,
        sortOrder: "desc",
        sortBy: "",
        preview: false,
        query: {
          id: "",
          documentType: params.documentType,
          originalId: +params.originalId,
        },
      };

      const response = await axios.post(
        "/api/search/GetSingleTopicAndTrend",
        request
      );
      const result = {
        documentType: params.documentType,
        originalId: params.originalId,
        data: response.data.response,
      };
      if (!result.data) {
        window.location.replace("/report-not-found");
      }
      if ((params.documentType === result.data.documentType) && (Number(params.originalId) === Number(result.data.originalId))) {
        return result;
      } else {
        window.location.replace("/report-not-found");
        return false;
      }
      
    } catch (error) {
      const newError = error;
      if (newError.response && newError.response.data && newError.response.data.title && newError.response.data.title !== '') {      
       return rejectWithValue(newError.response.data.title);
     }

     if (newError.response && newError.response.data && newError.response.data !== '') {
       return rejectWithValue(
         newError.response.data && newError.response.data !== ''
           ? newError.response.data
           : newError.response.statusText || "Unable to fetch report"
       );
     }
     return rejectWithValue("Network Error: Unable to fetch report");            
    }
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState: {
    loading: false,
    data: null,
    error: null,
    documentType: "",
    originalId: 0,
    uniqueId: 0,
    recentlyViewedRecords: [],
    showSection: {
      patient: false,
      summary: false,
      possibleDisruptions: false,
      threats: false,
      intervention: false,
      development: false,
      regulatory: false,
      comparators: false,
      outcomes: false,
      opportunities: false,
      recordHistory: false,
    },
  },
  reducers: {
    selectReport: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    getRecentlyViewedRecords: (state, action) => {
      state.recentlyViewedRecords = getStoredRecords();
    }
  },
  extraReducers: {
    [performGetSingleTopicOrTrend.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [performGetSingleTopicOrTrend.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [performGetSingleTopicOrTrend.fulfilled]: (state, action) => {
      let record = {
        documentType: action.payload.documentType,
        originalId: action.payload.originalId,
        uniqueId: action.payload.data.uniqueId,
        title: action.payload.data.title,
      };
      state.data = action.payload.data;

      state.documentType = action.payload.documentType;
      state.originalId = action.payload.originalId;
      state.uniqueId = action.payload.data.uniqueId;
      state.showSection = {
        patient:
          action.payload.data.patientPopulation ||
          (action.payload.data.diseasesConditions &&
            action.payload.data.diseasesConditions.length > 0) ||
          action.payload.data.diseaseSpecific,
        summary: !!action.payload.data.description,
        possibleDisruptions:
          action.payload.data.possibleDisruptions &&
          action.payload.data.possibleDisruptions.length > 0,
        threats: !!action.payload.data.threats,
        intervention:
          action.payload.data.interventionName ||
          action.payload.data.interventionUse ||
          action.payload.data.interventionClass ||
          action.payload.data.interventionDescription,
        development:
          (action.payload.data.developers &&
            action.payload.data.developers.length > 0) ||
          action.payload.data.developmentPhase,
        regulatory:
          action.payload.data.primaryCompletionDate ||
          action.payload.data.fdaSubmissionPlanned ||
          action.payload.data.submissionDate ||
          action.payload.data.regulatoryDecisionDate ||
          action.payload.data.approvalClearanceDate ||
          action.payload.data.latestRegulatoryEvent ||
          action.payload.data.fdaDesignations ||
          action.payload.data.clinicalTrials ||
          action.payload.data.regulatoryNotes,
        comparators: !!action.payload.data.comparators,
        outcomes: !!action.payload.data.outcomes,
        opportunities: !!action.payload.data.opportunities,
        recordHistory:
          (action.payload.data &&
            action.payload.data.hpdRs &&
            action.payload.data.hpdRs.length > 0) ||
          action.payload.data.archiveDate ||
          action.payload.data.publicationDate ||
          (action.payload.data.publicationHistory &&
            action.payload.data.publicationHistory.length > 0),
      };
      storeRecord(record);

      state.recentlyViewedRecords = getStoredRecords();
      state.loading = false;
    },
  },
});
export const { getRecentlyViewedRecords } = reportSlice.actions;
export const selectReportAction = reportSlice.actions.selectReport;
export default reportSlice.reducer;
