import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchLandingPages = createAsyncThunk(
    '/landingpage',
    async (request, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                '/api/systemconfiguration/getlandingpages'
            );
            return response.data.response;
        } catch (error) {
            if (error.response.data.title && error.response.data.title !== '') {
                return rejectWithValue(error.response.data.title);
            }
            return rejectWithValue(
                error.response.data && error.response.data !== ''
                    ? error.response.data
                    : error.response.statusText || "Unable to get landing page values"
            );
        }
    }
);

export const initialState = {
    loading: false,
    error: null,
    data: []
}

const landingPageSlice = createSlice({
    name: 'landingpage',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchLandingPages.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchLandingPages.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchLandingPages.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    }
});

export default landingPageSlice.reducer;