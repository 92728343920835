import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchTutorialsPage = createAsyncThunk(
    '/tutorialspage',
    async (request, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                '/api/systemconfiguration/getvideocontent'
            );
            return response.data.response;
        } catch (error) {
            if (error.response.data.title && error.response.data.title !== '') {
                return rejectWithValue(error.response.data.title);
            }
            return rejectWithValue(
                error.response.data && error.response.data !== ''
                    ? error.response.data
                    : error.response.statusText || "Unable to get landing page values"
            );
        }
    }
);

export const initialState = {
    loading: false,
    error: null,
    data: []
}

const tutorialsPageSlice = createSlice({
    name: 'tutorialspage',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchTutorialsPage.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchTutorialsPage.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchTutorialsPage.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    }
});

export default tutorialsPageSlice.reducer;