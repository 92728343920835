import React from "react";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ViewTrendsIcon from "@material-ui/icons/TrendingUp";
import Clamp from "react-multiline-clamp";
import styles from "./searchBar.module.scss";


export default (props) => {
  const { 
    recentRecords, 
    recentSearchesLength, 
    focusIndex,
    linkRefs,
    classes,
  } = props;
  return (
    <>
      <h4 className={styles.searchMenuSubheading}>Recently Viewed Records</h4>
      <ul>
        {recentRecords.map((item, index) => {
          const { title, documentType, originalId, uniqueId } = item;
          const currentIndex = index + recentSearchesLength;
          return (
            <li
              key={title + index}
              className={`${styles.searchItem} ${
                focusIndex === currentIndex ? styles.active : null
              }`}
            >
              <a
                className={styles.titleLabel}
                href={`/report/${documentType}/${originalId}`}
                ref={(title) => {
                  linkRefs[currentIndex] = title;
                }}
              >
                <div className={styles.iconCol}>
                  {documentType === "topics" ? (
                    <MenuBookIcon className={classes.iconResult} />
                  ) : (
                    <ViewTrendsIcon
                      className={classes.iconResult}
                      src={ViewTrendsIcon}
                    />
                  )}
                </div>
                <div className={styles.bodyCol}>
                  <span className={styles.idLabel}>
                    {documentType === "topics" ? "Topic ID: " : "Trend ID: "}
                    {uniqueId}
                  </span>
                  <Clamp lines={2}><div dangerouslySetInnerHTML={{ __html: title }} /></Clamp>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </>
  );
};
