import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { hydrate, render } from "react-dom";
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import App from "./App";
import store from "./store";
import userManager from "./utils/usermanager";
import "./App/scss/index.scss";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalCss from "./App/jss/GlobalCss";
import ScrollToTop from "./components/ScrollToTop";
import { Theme } from "./theme";
// import ScrollToHandler from "./components/ScrollToHandler";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

let persistor = persistStore(store);

if (process.env.NODE_ENV !== "production") {
  localStorage.setItem("debug", "pcori-ui:*");
}
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    
    <MuiThemeProvider theme={Theme}>
      <CssBaseline>
        <GlobalCss>
          <Router>
          <ScrollToTop>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </Provider>
            </ScrollToTop>
          </Router>
        </GlobalCss>
      </CssBaseline>
    </MuiThemeProvider>,
    rootElement
  );
} else {
  render(
    <MuiThemeProvider theme={Theme}>
      <CssBaseline>
        <GlobalCss>
          <Router>
          <ScrollToTop>
            <Provider store={store}>
              <OidcProvider store={store} userManager={userManager}>
                <PersistGate loading={null} persistor={persistor}>
                  <App />
                </PersistGate>
              </OidcProvider>
            </Provider>
            </ScrollToTop>
          </Router>
        </GlobalCss>
      </CssBaseline>
    </MuiThemeProvider>,
    rootElement
  );
}
