export const pageViewEvent = (pagename) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
  'event': 'Custom_PageViewEvent',
  'event_category': '',
  'event_action': '',
  'event_label': '',
  'pageview_name': 'pageview_' + pagename
  });
}

export const logEvents = (category, action, label) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
  'event': 'Custom_ClickEvent',
  'event_category': 'click_' + category,
  'event_action': 'click_' + action,
  'event_label': 'click_' + label,
  'pageview_name': ''
  });
}

// export const pageViewEvent = () => {
//   if (typeof window !== undefined && window.ga) {
//     window.ga(function () {
//       const tracker = window && window.ga && window.ga.getAll ? window.ga.getAll()[0] : null;
//       if (tracker) {
//         tracker.send("pageview", window.location.pathname);
//       } else {
//         console.log("GOOGLE Analytics is not available.");
//       }
//     });
//   } else {
//     (function (w, d, s, l, i) {
//       w[l] = w[l] || [];
//       w[l].push({
//         "gtm.start": new Date().getTime(),
//         event: "gtm.js",
//       });
//       var f = d.getElementsByTagName(s)[0],
//         j = d.createElement(s),
//         dl = l !== "dataLayer" ? "&l=" + l : "";
//       j.async = true;
//       j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
//       f.parentNode.insertBefore(j, f);
//     })(
//       window,
//       document,
//       "script",
//       "dataLayer",
//       process.env.REACT_APP_GOOGLE_ANALYTICS_ID
//     );
//   }
// };

// export const logEvents = (category, action, label) => {
//   if (typeof window !== undefined && window.ga) {

//     window.ga(function () {
//       const tracker =
//         window && window.ga && window.ga.getAll ? window.ga.getAll()[0] : null;
//       if (tracker) {
//         tracker.send("event", category, action, label);
//       } else {
//         console.log("GOOGLE Analytics is not available.");
//       }
//     });
//   } else {
//     (function (w, d, s, l, i) {
//       w[l] = w[l] || [];
//       w[l].push({
//         "gtm.start": new Date().getTime(),
//         event: "gtm.js",
//       });
//       var f = d.getElementsByTagName(s)[0],
//         j = d.createElement(s),
//         dl = l !== "dataLayer" ? "&l=" + l : "";
//       j.async = true;
//       j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
//       f.parentNode.insertBefore(j, f);
//     })(
//       window,
//       document,
//       "script",
//       "dataLayer",
//       process.env.REACT_APP_GOOGLE_ANALYTICS_ID
//     );
//   }
// };