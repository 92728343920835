import React from "react";
import { connect } from "react-redux";
import Root from "../components/Root";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { clearAll } from "../components/SearchResults/Export/exportSlice";
import { useDispatch } from "react-redux";
const App = (props) => {
  axios.defaults.headers.common["Content-type"] = "application/json";
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";
  const dispatch = useDispatch();

  const location = useLocation();

  React.useEffect(() => {
    dispatch(clearAll());
  }, [location]);

  return <Root {...props} />;
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
