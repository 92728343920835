import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchSystemConfigurations = createAsyncThunk(
    '/home',
    async (request, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                '/api/systemconfiguration/getcontent'
            );
            return response.data.response;
        } catch (error) {
          let newError = error;
          if (newError.response && newError.response.data.title && newError.response.data.title !== '') {
            
            return rejectWithValue(newError.response.data.title);
          }

          if (newError.response && newError.response.data && newError.response.data !== '') {
            return rejectWithValue(
              newError.response.data && newError.response.data !== ''
                ? newError.response.data
                : newError.response.statusText || "Unable to get system configuration values"
            );
          }
          return rejectWithValue("Network Error: Unable to contact API");       
        }
    }
);

export const initialState = {
    loading: false,
    error: null,
    data: []
}

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSystemConfigurations.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchSystemConfigurations.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchSystemConfigurations.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    }
});

export default homeSlice.reducer;