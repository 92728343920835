import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchFocusAreas = createAsyncThunk(
  "/focusarea",
  async (request, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/api/systemconfiguration/getfocusareas"
      );

      let adjustedData = [];

      if (response && response.data && response.data.response) {
        adjustedData = [
          ...response.data.response.filter(
            (f) => f.title !== "Other"
          ),
          ...response.data.response.filter(
            (f) => f.title === "Other"
          ),
        ];
      }

      return adjustedData;
    } catch (error) {
      if (error.response.data.title && error.response.data.title !== "") {
        return rejectWithValue(error.response.data.title);
      }
      return rejectWithValue(
        error.response.data && error.response.data !== ""
          ? error.response.data
          : error.response.statusText ||
              "Unable to get system configuration values"
      );
    }
  }
);

export const initialState = {
  loading: false,
  error: null,
  data: [],
};

const focusAreaSlice = createSlice({
  name: "focusarea",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFocusAreas.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchFocusAreas.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchFocusAreas.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default focusAreaSlice.reducer;
