import React, { forwardRef } from "react";
import Paper from "@material-ui/core/Paper";
import RecentSearchesSubMenu from "./RecentSearchesSubMenu";
import RecentRecordsSubMenu from "./RecentRecordsSubMenu";
import styles from "./searchBar.module.scss";

export default forwardRef((props, ref) => {
  const { 
    searchState, 
    reportState, 
    focusIndex,
    doSearch,
    hideAutoSuggest,
    linkRefs,
    classes,
  } = props;

  return (
    <Paper className={styles.searchMenu} ref={ref}>
      {searchState !== undefined && searchState.recentSearches.length > 0 && (
        <RecentSearchesSubMenu 
          recentSearches={searchState.recentSearches} 
          focusIndex={focusIndex}
          doSearch={doSearch}
          hideAutoSuggest={hideAutoSuggest}
          linkRefs={linkRefs}
        />
      )}

      {reportState !== undefined && reportState.recentlyViewedRecords.length > 0 && (
        <RecentRecordsSubMenu
          classes={classes}
          recentRecords={reportState.recentlyViewedRecords}
          recentSearchesLength={searchState.recentSearches.length}
          focusIndex={focusIndex}
          linkRefs={linkRefs}
        />
      )}
    </Paper>
  );
});
