import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchRecentlyUpdatedContent = createAsyncThunk(
    '/recentcontent',
    async (request, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                '/api/search/getrecentlyaddedcontent'
            );
            return response.data.response;
        } catch (error) {
            if (error.response.data.title && error.response.data.title !== '') {
                return rejectWithValue(error.response.data.title);
            }
            return rejectWithValue(
                error.response.data && error.response.data !== ''
                    ? error.response.data
                    : error.response.statusText || "Unable to get system configuration values"
            );
        }
    }
);

export const initialState = {
    loading: false,
    error: null,
    contentData: null
}

const recentContentSlice = createSlice({
    name: 'recentcontent',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchRecentlyUpdatedContent.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchRecentlyUpdatedContent.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchRecentlyUpdatedContent.fulfilled]: (state, action) => {
            state.loading = false;
            state.contentData = action.payload;
        }
    }
});

export default recentContentSlice.reducer;