import React, { useState, useRef, useEffect } from "react";
import logoImg from '../../App/images/logo-pcori.svg';
import { IconButton } from "@material-ui/core";
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';
import { Link } from "react-router-dom";
import Subheader from "./Subheader";
import styles from './header.module.scss';
import SmartLink from "../SmartLink";
import { logEvents } from "../../utils/analytics";

export default () => {
  const [isClosed, setIsClosed] = useState(true);
  let toggleIcon = isClosed ? <MenuIcon /> : <CloseIcon />;
  const menuRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsClosed(true);
      }
    }
    ['mousedown', 'scroll'].forEach(evt => document.addEventListener(evt, handleClickOutside, false));
    return () => {
      ['mousedown', 'scroll'].forEach(evt => document.removeEventListener(evt, handleClickOutside, false));
    };

  }, [menuRef]);

  return (
    <>
      <header className={`${styles.header}`}>
        <Subheader />
        <div className={`container ${styles.headerContainer}`}>
          <nav className={styles.headerNav}>
            <a className={`${styles.logoLink}`} href="https://www.pcori.org" aria-label="Patient-Centered Outcomes Research Institute">
              <img className={`${styles.logoImg}`} src={logoImg} alt="PCORI Logo" width="1200px" height="504px" />
              <span className={styles.logoTitle}>Patient-Centered Outcomes Research Institute</span>
            </a>
            <ul className={`${styles.navList}`}>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/" aria-label="Home">Home</Link>
              </li>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/about" aria-label="About">About</Link>
              </li>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/visualsummary" aria-label="Visual Summary">Visual Data Summary</Link>
              </li>
              <li className={styles.navItem}>
                <Link className={styles.navLink} to="/tutorials" aria-label="Tutorials">Tutorials</Link>
              </li>
              <li className={styles.navItem}>
                <Link
                  className={styles.navLink}
                  to="/contact"
                  title="Contact Us Link"
                  aria-label="Contact Us Link"
                  target="_self"
                  onClick={() => {
                    logEvents('Contact Us Link', 'Header Link', '/contact');
                  }}
                >
                  Contact Us
                </Link>
              </li>

            </ul>
          </nav>

          <IconButton
            className={styles.headerToggle}
            onClick={() => {
              setIsClosed(!isClosed);
            }}
            aria-label="Toggle Menu"
          >
            {toggleIcon}

          </IconButton>

        </div>
      </header>
      <div ref={menuRef} className={`${styles.mobileMenu} ${isClosed ? styles.isClosed : ''}`}>
        <nav className={styles.mobileMenuNav}>
          <ul className={`${styles.navList}`}>
            <li className={styles.navItem}>
              <Link className={styles.navLink} to="/" aria-label="Home">Home</Link>
            </li>
            <li className={styles.navItem}>
              <Link className={styles.navLink} to="/about" aria-label="About">About</Link>
            </li>
            <li className={styles.navItem}>
              <Link className={styles.navLink} to="/visualsummary" aria-label="Visual Data Summary">Visual Data Summary</Link>
            </li>
            <li className={styles.navItem}>
              <Link className={styles.navLink} to="/tutorials" aria-label="Tutorials">Tutorials</Link>
            </li>
            <li className={styles.navItem}>
              <SmartLink
                className={styles.navLink}
                href="contact"
                title="Contact Us Link"
                aria-label="Contact Us Link"
                target="_self"
                onClick={() => {
                  logEvents('Contact Us', 'Header Link', '/contact');
                }}
              >
                Contact Us
              </SmartLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
};
