import React, { useRef, useState, useEffect } from "react";
import SearchMenu from "./SearchMenu";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { getRecentlyViewedRecords } from "../Report/reportSlice";
import { ReactComponent as SearchIcon } from "../../App/images/icon-search-yellow.svg";
import { performSearch } from "../SearchResults/searchSlice";
import { makeStyles } from "@material-ui/core/styles";
import { logEvents } from "../../utils/analytics";

const useStyles = makeStyles(({ palette, data, ...theme }) => ({
  searchBar: {
    width: "2.7rem",
    position: "relative",
    borderRadius: "2rem",
    marginLeft: "auto",
    transition: "width .2s ease",
  },
  searchBarOpen: {
    width: "100%",
    position: "relative",
    borderRadius: ".2rem",
    marginLeft: "auto",
    transition: "width .2s ease",
  },
  searchField: {
    display: "flex",
    borderRadius: "2rem",
    transition: "all .2s ease",
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      marginLeft: theme.spacing(1),
      padding: "6px 0",
      flex: 1,
    },
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  searchFieldOpen: {
    display: "flex",
    borderRadius: ".2rem",
    transition: "all .2s ease",
    borderColor: "rgba(0, 0, 0, 0.2)",
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      marginLeft: theme.spacing(1),
      marginRight: "3rem",
      padding: "6px 0",
      flex: 1,

    },
    "& .MuiIconButton-root:before": {
      width: 0,
      height: 0,
      transition: "all .2s ease",
    },
    "& $searchIcon path": {
      fill: "#023a80",
      color: "#023a80",
      transition: "all .2s ease .2s",
    },
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },

  iconButton: {
    padding: "0.5rem .2rem",
    marginLeft: "-2.6rem",
    width: "2.6rem",
    backgroundColor: "#FFF",
    position: "relative",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "&:focus": {
      outline: "none",
    },
    "&:before": {
      display: "block",
      content: "''",
      position: "absolute",
      width: "3rem",
      height: "3rem",
      backgroundColor: "#023a80",
      zIndex: 0,
      borderRadius: "3rem",
      transition: "all .2s ease .25s",
    },
  },
  searchIcon: {
    "& path": {
      fill: "#f2d600",
      transition: "all .2s ease .25s",
    },
    zIndex: 1,
    transition: "all .2s ease .25s",
    width: "1rem",
    height: "1.6rem",
  },
  iconResult: {
    fill: "#3169b0",
    borderRadius: "2rem",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: palette.primary.main,
    padding: ".3rem",
    width: "1.6rem",
    height: "auto",
    marginRight: ".5rem",
  },
}));

export default (props) => {
  const { alwaysOpen = false } = props;

  const searchState = useSelector((state) => state.search)
    ? useSelector((state) => state.search)
    : [];
  const reportState = useSelector((state) => state.report)
    ? useSelector((state) => state.report)
    : [];
  const [focusIndex, updateFocusIndex] = useState(-1);
  const [toggleAutoSuggest, updateToggleAutoSuggest] = useState(false);
  const [totalSuggestIndex, updateTotalSuggestIndex] = useState([]);
  const [searchIsOpen, updateSearchIsOpen] = useState(alwaysOpen);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef();
  const searchMenuRef = useRef();
  const searchBarRef = useRef();
  const linkRefs = [];

  const keys = {
    ENTER: 13,
    UP: 38,
    DOWN: 40,
  };

  const doSearch = (searchPhrase) => {
    dispatch(
      performSearch({
        limit: 10,
        skip: 0,
        sortOrder: "desc",
        sortBy: "",
        preview: false,
        userPerformedSearch: true,
        currentFilterName: "",
        query: {
          searchPhrase: searchPhrase,
          recordStatus: [
            {
              id: "Active",
              title: "Active",
              selected: true,
            },
          ],
        },
      })
    );
  };

  const handleNavigation = (e) => {
    showAutoSuggest(e);
    let totalIndex =
      searchState.recentSearches.length +
      reportState.recentlyViewedRecords.length;
    switch (e.keyCode) {
      case keys.ENTER:
        if (
          linkRefs[focusIndex] !== undefined &&
          linkRefs[focusIndex].href !== undefined &&
          linkRefs[focusIndex].href.length > 0
        ) {
          let url = linkRefs[focusIndex].href;
          var newUrl = url.replace(
            //eslint-disable-next-line
            /^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/,
            "$1"
          );
          hideAutoSuggest(e);
          window.location = "/" + newUrl;
          return;
        }
        const inputValue = e.target.value;
        const suggestValue = searchState.recentSearches[focusIndex];
        let searchPhrase = "";
        searchPhrase = inputValue ? inputValue : suggestValue;
        onKeyPress(e, searchPhrase);
        hideAutoSuggest(e);
        hideSearchbar();
        break;
      case keys.UP:
        if (focusIndex > -1) {
          updateFocusIndex(focusIndex - 1);
        } else {
          inputRef.current.focus();
        }
        break;
      case keys.DOWN:
        if (focusIndex < totalIndex - 1) {
          updateFocusIndex(focusIndex + 1);
        }
        break;
      default:
    }
  };

  const getTotalSuggestIndex = () => {
    getRecentlyViewedRecords();  
    const recentSearchesCount = searchState && searchState.recentSearches ? searchState.recentSearches.length : 0;
    const recentRecordsCount = reportState && reportState.recentlyViewedRecords ? reportState.recentlyViewedRecords.length : 0;
    const totalIndex = recentSearchesCount + recentRecordsCount;
    updateTotalSuggestIndex(totalIndex);
    return totalIndex;
  }

  const showAutoSuggest = () => {
    if (!toggleAutoSuggest && getTotalSuggestIndex() && searchIsOpen) {
      updateToggleAutoSuggest(true);
    }
  };

  const hideAutoSuggest = (e) => {
    if (toggleAutoSuggest) {
      updateToggleAutoSuggest(false);
      updateFocusIndex(-1);
    }
  };

  const onKeyPress = (e, searchPhrase) => {
    doSearch(searchPhrase);
    history.push("/search");
    e.target.value = "";
    logEvents("Search", "Search", searchPhrase);
  };
  const handleSearch = (event, ref) => {
    if (!searchIsOpen) {
      openSearchbar();
      return;
    }
    let searchPhrase = "*";
    if (inputRef.current.value && inputRef.current.value.length > 0) {
      searchPhrase = inputRef.current.value;
    }
    if (
      (inputRef.current.childNodes !== undefined) > 0 &&
      inputRef.current.childNodes[0].value
    ) {
      searchPhrase = inputRef.current.childNodes[0].value;
    }
    doSearch(searchPhrase);
    history.push("/search");
    event.target.value = "";
    logEvents("Search", "Search", searchPhrase);
  };
  const handleClickOutside = (event) => {
    if (searchBarRef.current && searchBarRef.current.contains(event.target)) {
      showAutoSuggest();
      return;
    }
    if (searchMenuRef.current && searchMenuRef.current.contains(event.target)) {
      showAutoSuggest();
      return;
    }
    hideAutoSuggest(event);
    hideSearchbar();
  };

  const openSearchbar = (e) => {
    if (!searchIsOpen) {
      updateSearchIsOpen(true);
    }
  };
  const hideSearchbar = (e) => {
    if (!alwaysOpen) {
      if (searchIsOpen) {
        updateSearchIsOpen(false);
      }
    }
  };

  useEffect(() => {
    dispatch(
      getRecentlyViewedRecords()
    );      
  }, []);   

  useEffect(() => {
    getTotalSuggestIndex();
  }, [totalSuggestIndex]);

  useEffect(() => { 
    ["mousedown", "scroll"].forEach((evt) =>
      document.addEventListener(evt, handleClickOutside, false)
    );

    return () => {
      ["mousedown", "scroll"].forEach((evt) =>
        document.removeEventListener(evt, handleClickOutside, false)
      );
    };

  }, [
    toggleAutoSuggest,
    totalSuggestIndex,
    handleClickOutside,
    searchIsOpen,
    searchState,
    searchState.recentSearches,
  ]);

  return (
    <div
      className={`${
        searchIsOpen ? classes.searchBarOpen : classes.searchBar
      }`}
    >
      <Paper
        className={`${
          searchIsOpen ? classes.searchFieldOpen : classes.searchField
        }`}
        elevation={0}
        variant="outlined"
        ref={searchBarRef}
      >
        <InputBase
          className={classes.searchInput}
          placeholder="Search Horizon Scanning Database"
          inputProps={{
            "aria-label": "Search Horizon Scanning Database",
          }}
          onKeyUp={(e) => {
            handleNavigation(e);
          }}
          ref={inputRef}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          onClick={(event) => {
            handleSearch(event, inputRef);
          }}
        >
          <SearchIcon className={classes.searchIcon} />
        </IconButton>
      </Paper>
      {toggleAutoSuggest &&
        searchState !== undefined &&
        reportState !== undefined &&
        (!searchState.loading || !reportState.loading) && (
          <SearchMenu
            ref={searchMenuRef}
            searchState={searchState}
            reportState={reportState}
            focusIndex={focusIndex}
            doSearch={doSearch}
            hideAutoSuggest={hideAutoSuggest}
            linkRefs={linkRefs}
            classes={classes}
          />
        )}
    </div>
  );
};
