import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

const userManagerConfig = {
  authority: process.env.REACT_APP_STS_AUTHORITY,
  client_id: process.env.REACT_APP_STS_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/callback`,
  response_type: 'token id_token',
  scope: 'openid ecri.profile pcori_api',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: sessionStorage }),

  // userStore: new WebStorageStateStore({ store: localStorage }),
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
