import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchGlossaryPage = createAsyncThunk(
    '/glossarypage',
    async (request, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                '/api/systemconfiguration/getglossary'
            );
            return response.data.response;
        } catch (error) {
            const newError = error;

            if (newError.response && newError.response.data.title && newError.response.data.title !== '') {              
              return rejectWithValue(newError.response.data.title);
            }
  
            if (newError.response && newError.response.data && newError.response.data !== '') {
              return rejectWithValue(
                newError.response.data && newError.response.data !== ''
                  ? newError.response.data
                  : newError.response.statusText || "Unable to fetch glossary terms."
              );
            }
            return rejectWithValue("Network Error: Unable to fetch glossary terms.");              
        }
    }
);

export const initialState = {
    loading: false,
    error: null,
    data: []
}

const glossaryPageSlice = createSlice({
    name: 'glossarypage',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchGlossaryPage.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [fetchGlossaryPage.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [fetchGlossaryPage.fulfilled]: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        }
    }
});

export default glossaryPageSlice.reducer;