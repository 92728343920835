//import React from "react";
import { variableStyles } from "./_variables";
import { typographyStyles } from "./_typography";
import { layoutStyles } from "./_layout";

const GlobalCss = ({ children }) => {
  variableStyles();
  typographyStyles();
  layoutStyles();
  return children;
 };

 export default GlobalCss;
