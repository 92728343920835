import { createTheme } from "@material-ui/core/styles";
import { openSans } from "./App/jss/webfonts";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import variables from "./App/scss/_appvariables.scss";

const breakpoints = createBreakpoints({});

const themeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    type: "light",
    primary: {
      main: '#001E61',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      //main: '#64aa11',
      main: '#386800',
      contrastText: '#ffffff',
    },
    error: {
      main: '#e53935',
    },
    // contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
    fontFamily: variables.displayprimaryfontfamily,
    fontSize: 14,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [openSans],
      },     
    }, 
    MuiChip: {
      root: {
        height: "auto",
        "& .MuiChip-label": {
          whiteSpace: "pre-wrap",
          height: "auto",       
        }
      }
    },      
    MuiButton: {
      root: {
        textTransform: "none",
        fontSize: variables.basemobilefontsize,
        "&:focus": {
          outline: "none",
        },
        [breakpoints.up("md")]: {
          fontSize: variables.basefontsize,
        }, 
      },
      label: {
        fontSize: variables.basemobilefontsize,
      }
    },
    MuiCard: {
      root: {
        borderRadius: 8,      
      },
    },
    MuiFab: {
      root: {
        textTransform: "none",
      },
    },
  },
};

export const Theme = createTheme({
  ...themeOptions,
});

export const FooterTheme = createTheme({
  ...themeOptions,
  palette: {
    type: "dark",
  },
});
