/* eslint-disable no-useless-escape, react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// import useBase from '../hooks/useBase';

const isRelativeUrlRegex = /^[^\/]+\/[^\/].*$|^\/[^\/].*$/;
const fileExtensionRegex = /\.([0-9a-z]+)(?:[\?#]|$)/i;
const defaultTarget = '_blank';
var hrefTitle = '';
const defaultRel = 'nofollow noopener noreferrer';
const sameSiteRegex = new RegExp(
  `https?:\/\/${process.env.REACT_APP_SITE_DOMAIN}/`
);

const SmartLink = (props) => {
  // const { logEvent } = useBase();

  const href = props.href ? props.href.replace(sameSiteRegex, '') : '';
  hrefTitle = props.title === '' ? props.href : props.title;

  const onClickHandler = (e) => {
    // if (props.ga) {
    //   const { category, action, label, value } = props.ga;
    //   logEvent(category, action, label, value);
    // }
    if (props.onClick) {
      props.onClick(e);
    }
  };
  if (!isRelativeUrlRegex.test(href)) {
    return (
      <a
        href={href}
        id={props.id ? props.id : ''}
        target={props.target ? props.target : defaultTarget}
        title={hrefTitle}
        rel={defaultRel}
        className={props.className}
        aria-label={hrefTitle}
        onClick={onClickHandler}
      >
        {props.children}
      </a>
    );
  }

  // if url has an extension, we'll assume it's some sort of asset
  if (href.match(fileExtensionRegex)) {
    return (
      <a
        href={href}
        id={props.id ? props.id : ''}
        title={hrefTitle}
        className={props.className}
        aria-label={hrefTitle}
        onClick={onClickHandler}
      >
        {props.children}
      </a>
    );
  }

  // use gatsby link for relative urls so we hook into react router
  return (
    <Link
      to={`${href.toLowerCase()}${href.endsWith('/') ? '' : '/'}`}
      id={props.id ? props.id : ''}
      className={props.className}
      aria-label={`${href.toLowerCase()}${href.endsWith('/') ? '' : '/'}`}
      onClick={onClickHandler}
    >
      {props.children}
    </Link>
  );
};

SmartLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  // ga: PropTypes.shape({
  //   category: PropTypes.string,
  //   action: PropTypes.string,
  //   label: PropTypes.string,
  //   value: PropTypes.number,
  // }),
};

SmartLink.defaultProps = {
  className: '',
  children: null,
};

export default SmartLink;
