import React, { Suspense, lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../Header";
import CallbackPage from "../Callback";
import Footer from "../Footer";
import LoadingSpinner from "../LoadingSpinner";
import PageNotFound from "../PageNotFound";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const ogThumb =
  "https://d84vr99712pyz.cloudfront.net/p/images1/ecri-trusted-voice-healthcare.jpg";

  const publicURl =
  "https://horizonscandb.pcori.org/";

//const asyncHome = lazy(() => import("../Home" /* webpackChunkName: "home" */));
const asyncTemplate = lazy(() =>
  import("../DemoTemplate" /* webpackChunkName: "demotemplate" */)
);
const asyncVisualSummary = lazy(() =>
  import("../VisualSummaryPage" /* webpackChunkName: "visualsummarypage" */)
);
const asyncSearchResults = lazy(() =>
  import("../SearchResults" /* webpackChunkName: "searchresults" */)
);
const asyncValues = lazy(() =>
  import("../Values" /* webpackChunkName: "Values" */)
);
const asyncReport = lazy(() =>
  import("../Report" /* webpackChunkName: "Report" */)
);

const asyncHome = lazy(() =>
  import("../Home" /* webpackChunkName: "home" */)
);

const asyncLandingPage = lazy(() =>
  import("../LandingPage" /* webpackChunkName: "landingpage" */)
);
const asyncGlossaryPage = lazy(() =>
  import("../GlossaryPage" /* webpackChunkName: "glossarypage" */)
);

const asyncPageNotFound = lazy(() =>
  import("../PageNotFound" /* webpackChunkName: "pagenotfound" */)
)
const asyncTutorialsPage = lazy(() =>
  import("../TutorialsPage" /* webpackChunkName: "tutorialspage" */)
);
class Root extends React.Component {
  render() {
    const { isLoadingUser, location } = this.props;

    return (
      <React.Fragment>
        <Helmet title="PCORI® Horizon Scanning Database">
          <meta name="title" content="PCORI® Horizon Scanning Database" />
          <meta name="description" content="PCORI® Horizon Scanning Database" />
          <meta name="keywords" content="PCORI® Horizon Scanning Database" />

          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="PCORI® Horizon Scanning Database" />
          <meta property="og:title" content="PCORI® Horizon Scanning Database" />
          {location && location.href && (
            <meta property="og:url" content={publicURl} />
          )}
          <meta property="og:image" content={ogThumb} />
          <meta property="og:description" content="PCORI® Horizon Scanning Database" />
        </Helmet>

        <Header />

        <div className="main">
          {!isLoadingUser && (
            <Suspense fallback={<LoadingSpinner isCentered={true} />}>
              <Switch>
                <Route exact path="/" component={asyncHome} />
                <Route exact path="/visualsummary" component={asyncVisualSummary} />
                <Route path="/about" component={asyncLandingPage} />
                <Route exact path="/credits/" component={asyncLandingPage} />
                <Route path="/contact" component={asyncLandingPage} />
                <Route path="/glossary" component={asyncGlossaryPage} />
                <Route path="/callback" component={CallbackPage} />
                <Route path="/values" component={asyncValues} />
                <Route path="/demo" component={asyncTemplate} />
                <Route path="/search" component={asyncSearchResults} />
                <Route path="/tutorials" component={asyncTutorialsPage} />
                {/* <Route path="/report/:documentType/:originalId">
                  <Report />
                </Route> */}
                <Route path="/report/:documentType/:originalId" component={asyncReport} />
                <Route 
                  path="/report-not-found" 
                  render={(props) => (
                    <PageNotFound {...props} pageType="report"  />
                  )}
                />
                <Route 
                  path="/result-not-found" 
                  render={(props) => (
                    <PageNotFound {...props} pageType="search"  />
                  )}
                />
                <Route 
                  path="/error" 
                  render={(props) => (
                    <PageNotFound {...props} pageType="error"  />
                  )}
                />                
                <Route component={asyncPageNotFound} />                
              </Switch>
            </Suspense>
          )}
        </div>
        <Footer
          facebookurl="https://www.facebook.com/ECRIInstitute/"
          twitterurl="https://twitter.com/ECRI_Institute"
          youtubeurl="https://www.youtube.com/user/ECRIInstitute"
          linkedinurl="https://www.linkedin.com/company/ecri-institute"
          chaturl="https://ecrichat.ecri.org/chat/chatstart.aspx?domain=www.ecri.org&link=https://guidelines.ecri.org/ "
          rootUrl={process.env.REACT_APP_PUBLIC_URL}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Root));
