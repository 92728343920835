import React from "react";
import logoImgInverted from "../../App/images/logo-pcori-inverted.svg";
import styles from "./footer.module.scss";
import { Link } from "react-router-dom";
import SmartLink from "../SmartLink";

import metadata from "../../metadata.json";
export default () => {
  let currentYear = new Date().getFullYear();
  return (
    <div className={styles.footer}>
      <div className="container">

        <div className={`row ${styles.footerTop}`}>
          <div className="col-xs-12">
            <SmartLink
              className={styles.logoLink}
              href="https://www.pcori.org/"
              title="PCORI Logo"
              aria-label="PCORI Logo"
              target="_self"
            >
              <img className={`${styles.logoImg}`} src={logoImgInverted} alt="PCORI Inverted Logo" width="763px" height="575px" />
            </SmartLink>
            <address className={styles.address}>
              <p>
              1333 New Hampshire Avenue NW, Suite 1200 <br />
              Washington, DC 20036<br />
                <a className={styles.link} href="tel:202-827-7700" aria-label="Phone: (202) 827-7700">Phone: (202) 827-7700</a> | Fax: (202) 355-9558 <br />
                <a className={styles.link} href="mailto:info@pcori.org" aria-label="Email: info@pcori.org">info@pcori.org</a>
              </p>
            </address>
          </div>
        </div>
        <div className={`row ${styles.footerBottom}`}>
          <div className={`col-xs-12`}>
            <div className={styles.border} />
            <div className={`${styles.bottomBody} row`}>
              <span className={`${styles.copyright} col-xs-12 col-lg-6`}>&copy; 2011-{currentYear} Patient-Centered Outcomes Research Institute. All Rights Reserved.</span>
              <ul className={`${styles.bottomFooterList} col-xs-12 col-lg-6`}>
                <li className={styles.bottomListItem}><a className={styles.link} href="https://www.pcori.org/about-us/privacy-policy" target="_blank" rel="noopener noreferrer" aria-label="Privacy Policy">Privacy Policy</a></li>
                <li className={styles.bottomListItem}><a className={styles.link} href="https://www.pcori.org/about-us/terms-use" target="_blank" rel="noopener noreferrer" aria-label="Terms of Use">Terms of Use</a></li>
                <li className={styles.bottomListItem}><a className={styles.link} href="https://www.pcori.org/about-us/governance/trademark-usage-guidelines" target="_blank" rel="noopener noreferrer" aria-label="Trademark Usage Guidelines">Trademark Usage Guidelines</a></li>
                <li className={styles.bottomListItem}><a className={styles.link} href="https://www.pcori.org/webform/subscribe" target="_blank" rel="noopener noreferrer" aria-label="Subscribe">Subscribe</a></li>
                <li className={styles.bottomListItem}><Link className={styles.link} to="/credits" aria-label="Credits">Credits</Link></li>
              </ul>
            </div>
            <span className={styles.metaData}>
              {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
};
