
import OpenSans400Woff2 from "../scss/webfonts/open-sans-400-latin-ext.woff2";

export const openSans = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Open Sans'),
    local('open-sans-400-latin-ext'),
    url(${OpenSans400Woff2}) format('woff2')
  `,
  unicodeRange:
    'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',  
}