import React from "react";
import { CircularProgress } from "@material-ui/core";
import styles from "./loadingSpinner.module.scss"

export default (props) => {
  const {
    color,
    size,
    isCentered = false,
  } = props; 
  
  return (
    <CircularProgress className={isCentered ? styles.centered : ''} color={color} size={size} />
  );
}