import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchChartData = createAsyncThunk(
  "/chartstats",
  async (request, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/search/getstats");
      return response.data.response;
    } catch (error) {
      if (error.response.data.title && error.response.data.title !== "") {
        return rejectWithValue(error.response.data.title);
      }
      return rejectWithValue(
        error.response.data && error.response.data !== ""
          ? error.response.data
          : error.response.statusText || "Unable to get stat values"
      );
    }
  }
);

export const initialState = {
  loading: false,
  error: null,
  data: [],
};

const chartDataSlice = createSlice({
  name: "chartstats",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchChartData.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchChartData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [fetchChartData.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default chartDataSlice.reducer;
