import React from "react";
import SearchBar from "../../SearchBar";
import styles from "./subheader.module.scss";


export default () => {
  return (
    <div className={`${styles.subheader}`}>
      <div className="container">
        <div className={`row end-xs`}>
          <div className="col-xs-12 col-md-6 col-md-offset-6 col-lg-offset-7 col-lg-5">
            <SearchBar />
          </div>
        </div>
      </div>
    </div>
  );
};
