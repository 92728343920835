import { combineReducers } from "redux";
import reportReducer from "../../components/Report/reportSlice";
import searchReducer from "../../components/SearchResults/searchSlice";
import homeReducer from "../../components/Home/homeSlice";
import focusAreaReducer from "../../components/Home/BrowseFocusArea/focusAreaSlice";
import recentContentReducer from "../../components/Home/RecentlyUpdatedContent/recentContentSlice";
import exportReducer from "../../components/SearchResults/Export/exportSlice";
import landingPageReducer from "../../components/LandingPage/landingPageSlice";
import glossaryPageReducer from "../../components/GlossaryPage/glossaryPageSlice";
import tutorialsPageReducer from "../../components/TutorialsPage/tutorialsPageSlice";

import statsReducer from "../../components/VisualSummaryPage/visualSummarySlice";

export default combineReducers({
  report: reportReducer,
  search: searchReducer,
  home: homeReducer,
  focusArea: focusAreaReducer,
  recentContent: recentContentReducer,
  export: exportReducer,
  landingPage: landingPageReducer,
  glossaryPage: glossaryPageReducer,  
  stats: statsReducer,
  tutorialsPage: tutorialsPageReducer,
});
