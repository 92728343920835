import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
  FLUSH,
  PAUSE,
  PERSIST,
} from "redux-persist";
import rootReducer from "./reducers";

const loggerMiddleware = () => (next) => (action) => {
  //TODO: Additional logging other than GA will be implemented here.
  next(action);
};

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["home", "focusArea", "recentContent", "report"],
};

const defaultMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER,
        "/export/fulfilled",
      ],
    },
    immutableCheck: false,
  });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [loggerMiddleware, ...defaultMiddleware({immutableCheck: false})],
  immutableCheck: false,
});

export default store;
