import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import EmptyIcon from "../../App/images/icon-empty-colored.svg";
import Icon404 from "../../App/images/icon-404.svg";
import IconError from "../../App/images/icon-error-colored.svg";
import IconSearchExample from "../../App/images/icon-search-example.svg";
import SearchBar from "../SearchBar";
import LoadingSpinner from "../LoadingSpinner";
import AlertBar from "../AlertBar";
import { performSearch } from "../SearchResults/searchSlice";
import { logEvents } from "../../utils/analytics";
import Tooltip from '@material-ui/core/Tooltip';
import styles from './pagenotfound.module.scss';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const doSearch = (searchPhrase, dispatch, recordType ) => {
  dispatch(
    performSearch({
      limit: 10,
      skip: 0,
      sortOrder: "desc",
      sortBy: "",
      preview: false,
      userPerformedSearch: true,
      currentFilterName: "",
      query: {
        searchPhrase: searchPhrase,
        recordStatus: [
          {
            id: recordType,
            title: recordType,
            selected: true,
          },
        ],
      },
    })
  );
};

const ReportNotFound = (props) => {
  return (
    <div className={`row center-xs ${styles.emptySection}`}>
      <div className={`col-xs-12`}>
        <img
          className={styles.emptyIcon}
          src={EmptyIcon}
          alt="Empty Icon"
        />
        <h2 className={styles.emptyHeading}>No Report Found</h2>
        <p className={styles.emptyBody}>
          Sorry, we cannot find the report you are looking for.
        </p>
      </div>
  </div>
  )
}



const CountMessage = (props) => {
  const { archivedHits, searchPhrase, dispatch } = props;
  if (archivedHits) {
    return (
      <p>We cannot find the item you are looking for in the Active Records inventory.
      However, there does appear to be {archivedHits > 1 ? (<><strong>{archivedHits}</strong> possible matches</>) : `a possible match`} in the&nbsp;
      <StyledTooltip 
        title="An archived record is a record that is no longer being monitored by the PCORI Health Care Horizon Scanning System either because the intervention no longer shows potential for disruption or the intervention has been approved and adopted by the healthcare system." 
        placement="bottom"
        PopperProps={{
          disablePortal: true,
        }}
      >            
        <Link
          className={`${styles.searchItemLink} searchItemLink`}
          to={"/search"}
          title={searchPhrase}
          onClick={(e) => {
            doSearch(searchPhrase, dispatch, "Archived");
            logEvents("Search - Autosuggest", "Autosuggest Click", searchPhrase);
          }}                       
        >        
            Archived Records inventory
        </Link>
      </StyledTooltip>.
      </p>
    );
  }

  return (<p>We cannot find the item you are looking for in the Active Records inventory. Please try using a different search term.</p>)
}

const SearchNotFound = (props) => {
  const { 
    archivedHits,
    searchPhrase, 
    dispatch 
  } = props;

  return (
    <div className={`row center-xs ${styles.emptySection}`}>
      <div className={`col-xs-12`}>
        <img
          className={styles.emptyIcon}
          src={EmptyIcon}
          alt="Empty Icon"
        />
        <h2 className={styles.emptyHeading}>No Active Results Found {searchPhrase && (<>for <span className={styles.searchPhrase}>{searchPhrase}</span></>)}</h2>
        <CountMessage archivedHits={archivedHits} searchPhrase={searchPhrase} dispatch={dispatch} />
      </div>
  </div>
  )
}
const DefaultPage = (props) => {
  const { errorData = "" } = props;
  return (
    <div className={`row center-xs ${styles.emptySection}`}>
      <div className={`col-xs-12`}>
        { errorData ? 
          (<img
            className={styles.icon404}
            src={IconError}
            alt="Error Icon"
          />) : (
          <img
            className={styles.icon404}
            src={Icon404}
            alt="404 Icon"
          />            
          )   
        }
        <h2 className={styles.emptyHeading}>{ errorData ? errorData : "Page not Found"}</h2>
        <div className={styles.searchBarContainer}>
          { !errorData && <SearchBar alwaysOpen={true} />}
        </div>
        <div className={styles.body}>
          { !errorData && (
            <>
            <p>
              We're sorry, the page you are looking for does not appear to exist or may have moved. Please try one of the following:
            </p>
            <ul className={styles.bulletList}>
              <li>Check if the URL is correct.</li>
              <li>Search for the content using the search bar above, or by selecting the search icon <img className={styles.iconSearchExample} src={IconSearchExample} alt="Icon search example" /> in the browser.</li>
            </ul>
            <p>If neither of these work, please <Link to="/contact">contact us</Link>.</p>
            </>
          )}

        </div>

      </div>
  </div>
  )
}


export default (props) => {
  const { pageType = "default" } = props;
  const searchState = useSelector((state) => state.search)
    ? useSelector((state) => state.search)
    : [];
  const { loading } = searchState;
  const dispatch = useDispatch();
  const [activeSearchState, setActiveSearchState] = useState('');
  const [archivedSearchState, setArchivedSearchState] = useState('');
  const [archivedHits, setArchivedHits] = useState(0);
  const errorData = props.location.pathname === "/error" ? props.location.state.errorData : '';
  const searchPhrase = searchState ? searchState.data.request.query.searchPhrase : '';
  const recordType = searchState ? searchState.data.request.query.recordStatus[0].title : '';

  

   useEffect(() => {
    
    if (!activeSearchState && recordType === "Active") {
      setActiveSearchState(searchState);
      doSearch(searchPhrase, dispatch, "Archived");
      logEvents("Auto Search (archived results)", "Page Load", searchPhrase);
    }  
    
    if (!archivedSearchState && recordType === "Archived") {
      setArchivedSearchState(searchState);
      setArchivedHits(searchState.data.totalHits);      
    }

  });  

  return (
    <>
      {loading ? (
        <LoadingSpinner isCentered={true} />
      ) : (
        <>
        {errorData && <AlertBar message={errorData} />}
          <div className="page-content">
            <div className="page-content">
              <div className="container">
                { pageType === "error" && <DefaultPage errorData={errorData} />}
                { pageType === "report" && <ReportNotFound />}
                { pageType === "search" && 
                <SearchNotFound 
                  archivedHits={archivedHits} 
                  dispatch={dispatch} 
                  doSearch={doSearch} 
                  searchPhrase={searchPhrase}
                />}
                { pageType === "default" && <DefaultPage />}
              </div>
            </div>
        </div>
        </>
      )}
    </>
  );
};

