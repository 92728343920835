import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  loading: false,
  selectedItemsForExport: [],
  error: null,
  selectAll: false,
};

export const performExport = createAsyncThunk(
  "/export",
  async (request, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/export", request, {
        responseType: "blob",
      });

      return response.data;
    } catch (error) {
      const newError = error;

      if (newError.response && newError.response.data && newError.response.data.title && newError.response.data.title !== '') {       
        return rejectWithValue(newError.response.data.title);
      }

      if (newError.response && newError.response.data && newError.response.data !== '') {
        return rejectWithValue(
          newError.response.data && newError.response.data !== ''
            ? newError.response.data
            : newError.response.statusText || "Unable to get system configuration values"
        );
      }
      return rejectWithValue("Network Error: Unable to contact export API");         
    }
  }
);

const getBlobFormat = (exportFormat) => {
  switch (exportFormat) {
    case "ris":
      return {
        blobType: "application/x-Research-Info-Systems",
        fileExt: "ris",
      };
    case "xml":
      return {
        blobType: "application/xml",
        fileExt: "xml",
      };
    case "csv":
      return {
        blobType: "text/csv",
        fileExt: "csv",
      };
    default:
      return {
        blobType: "text/plain",
        fileExt: "txt",
      };
  }
};

const exportSlice = createSlice({
  name: "export",
  initialState: initialState,
  reducers: {
    selectItem: (state, action) => {
      if (
        !state.selectedItemsForExport.some(
          (item) => item.id === action.payload.id
        )
      ) {
        state.selectedItemsForExport.push(action.payload);
      } else {
        state.selectedItemsForExport = state.selectedItemsForExport.filter(
          (f) => f.id !== action.payload.id
        );
      }
    },
    selectAllItems: (state) => {
      state.selectAll = !state.selectAll;
    },
    clearAll: (state) => {
      state.selectAll = false;
      state.loading = false;
      state.error = null;
      state.selectedItemsForExport = [];
    },
  },
  extraReducers: {
    [performExport.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [performExport.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [performExport.fulfilled]: (state, action) => {
      state.loading = false;
      let blobFormat = getBlobFormat("csv");
      let blob = new Blob([action.payload], { type: blobFormat.blobType });
      const url = window.URL.createObjectURL(blob);
      const filename = `Horizon-Scanning-Database-Results_${new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "_")}.${blobFormat.fileExt}`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.outerHTML = "";
      state.selectedItemsForExport = [];
      state.selectAll = false;
      window.location.reload(false);
      
    },
  },
});
export const { selectItem, selectAllItems, clearAll } = exportSlice.actions;
export default exportSlice.reducer;
