import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";
const RECENT_SEARCH_TERMS = "RECENT_SEARCH_TERMS";
const getStoredSearchTerms = () => {
  let item = [];
  try {
    // Get from local storage by key
    item = window.localStorage.getItem(RECENT_SEARCH_TERMS);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : [];
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return item;
  }
};

const storeSearchTerm = (searchTerm) => {
  try {
    if (searchTerm) {
      let searchTerms = getStoredSearchTerms();

      const termExist = searchTerms.some((term) => term === searchTerm);

      if (!termExist) {
        searchTerms.push(searchTerm);
      }

      if (searchTerms.length > 5) {
        searchTerms.shift();
      }

      window.localStorage.setItem(
        RECENT_SEARCH_TERMS,
        JSON.stringify(searchTerms)
      );
    }
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};

export const performSearch = createAsyncThunk(
  "/search",
  async (request, { rejectWithValue }) => {

    try {
      const response = await axios.post(
        "/api/search/searchtopicsandtrends",
        request
      );
      return response.data.response;
    } catch (error) {
      const newError = error;      

      if (newError.response && newError.response.data && newError.response.data.title && newError.response.data.title !== '') {
       
       return rejectWithValue(newError.response.data.title);
     }

     if (newError.response && newError.response.data && newError.response.data !== '') {
       return rejectWithValue(
         newError.response.data && newError.response.data !== ''
           ? newError.response.data
           : newError.response.statusText || "Unable to get system configuration values"
       );
     }
     return rejectWithValue("Network Error: Unable to contact API");        
    }
  }
);

const searchSlice = createSlice({
  name: "search",
  initialState: {
    loading: false,
    data: null,
    error: null,
    userPerformedSearch: false,
    currentFilterName: "",
    currentFilterCategory: "",
    currentFilterItems: [],
    filterAccordionsExpanded: false,
    resultView: "list",
    recentSearches: [],
    pageSize: 10,
    covid19Selected:false
  },
  reducers: {
    cacheCurrentFilterItems: (state, action) => {
      state.currentFilterItems = action.payload.items;
      state.currentFilterName = action.payload.currentFilterName;
      state.currentFilterCategory = action.payload.currentFilterCategory;  
    },
    changeView: (state, action) => {
      state.resultView = action.payload;
    },
    clearRecentSearches: (state) => {
      state.recentSearches = [];
    },
    covid19Select: (state, action) => {
      state.covid19Selected = action.payload;
    },
  },
  extraReducers: {
    [performSearch.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.userPerformedSearch = action.meta.arg.userPerformedSearch;
      storeSearchTerm(action.meta.arg.query.searchPhrase);
    },
    [performSearch.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.userPerformedSearch = false;
    },
    [performSearch.fulfilled]: (state, action) => {
      const origHorizonScanningValues = action.payload && Array.isArray(action.payload.horizonScanningFocusAreaCounts) ? action.payload.horizonScanningFocusAreaCounts : [];
      const freshUncachedResponse = action.payload && Array.isArray(action.payload[state.currentFilterName]) ? action.payload[state.currentFilterName] : [];
      state.data = action.payload;
      state.userPerformedSearch = false;
      state.currentFilterCategory = action.meta.arg.currentFilterCategory;

   
      if (
        state.currentFilterName !== "recordStatusCounts" &&
        state.currentFilterName !== "documentTypeCounts"
      ) {
          state.data[state.currentFilterName] = state.currentFilterItems;
        // Checks is covid is selected and if true disables cache and loads original values.
        if (state.currentFilterName === "horizonScanningFocusAreaCounts" && state.covid19Selected) {
          state.data[state.currentFilterName] = origHorizonScanningValues;
        } 
      }
      if (action.payload && action.payload.request) {
        if (!action.payload.request.query[state.currentFilterCategory] || (action.payload.request.query[state.currentFilterCategory] && Array.isArray(action.payload.request.query[state.currentFilterCategory]) && action.payload.request.query[state.currentFilterCategory].length === 0)) {
          state.data[state.currentFilterName] = freshUncachedResponse;
        } 
      }

      state.recentSearches = getStoredSearchTerms().reverse();
      state.loading = false;
    },
  },
});
export const { cacheCurrentFilterItems, changeView, covid19Select } = searchSlice.actions;
export default searchSlice.reducer;
