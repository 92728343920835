import React from "react";
import { CallbackComponent } from "redux-oidc";
import { withRouter } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import userManager from "../../utils/usermanager";
import Log from "../../utils/log";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";

const CallbackPage = ({ updateLastLoginDate, getUserRoles, history }) => (
  <div className="page-content">
    <div className="container">
      <div className="row">
        <div>
          <h1>Redirecting...</h1>
        </div>
        <div className="col-xs-12">
          <CallbackComponent
            userManager={userManager}
            successCallback={(u) => {
              Log.info(history);

              updateLastLoginDate(u);

              getUserRoles(u);
              history.push({ ...u.state });
            }}
            errorCallback={(error) => {
              // TODO show an error page
              Log.error(`Login error ${error}`);
            }}
          >
            <LoadingSpinner isCentered={true} />
          </CallbackComponent>
        </div>
      </div>
    </div>
  </div>
);

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(CallbackPage));
